/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import { Heading, Link } from '@lmig/lmds-react';
import LmdsIcons from '@lmig/dotcom-aspect-components/Icon/LmdsIcons';
import Dot from '../Dot';
import AgentQuoteCard from './AgentQuoteCard';
import OfficeInfoCard from '../Office/OfficeInfoCard';
import Hours from '../Hours';

const { className: headingClass, styles: headingStyles } = css.resolve`
  @import './global-styles/tokens';
  .lm-Heading {
    margin: 0 0 0 0.5rem;
    font-size: 1.25rem;
  }
`;

const { className: truncateLinkClass, styles: truncateLinkStyles } = css.resolve`
  .lm-Link {
    position: relative;
    bottom: 1rem;
    margin: 2rem 1rem 0 2.5rem;
  }
`;

const agentServicesOrdering = [
  'Car insurance',
  'Home insurance',
  'Umbrella insurance',
  'Life insurance',
  'Insurance consulting',
  'Boat insurance',
  'ATV insurance',
  'Classic car insurance',
  'Condo insurance',
  'E-bike insurance',
  'Golf cart insurance',
  'Jet ski insurance',
  'Jewelry insurance',
  'Landlord insurance',
  'LSV insurance',
  'Motorcycle insurance',
  'Pet insurance',
  'Renters insurance',
  'RV insurance',
  'Small business insurance',
  'Smart phone/device insurance',
  'Snowmobile insurance',
  'UTV insurance',
  'Vacation rental insurance',
  'Watercraft insurance',
  '401k consulting',
  'Advice choosing insurers',
  'Annuities',
  'Asset protection',
  'Helping select coverage limits',
  'Income replacement'
];

const AboutMeList = ({ heading, items, listType, icon }) => {
  const [truncate, setTruncate] = useState(true);

  return (
    items?.length > 0 &&
    <div className="aboutMeListWrapper">
      <div className="aboutMeIconHeading">
        <span style={{ height: "2rem", width: "2rem" }}>
          <LmdsIcons icon={`${icon}`} />
        </span>
        <Heading type="h5-bold" tag="h3" className={headingClass}>{`${heading}`}</Heading>
      </div>
      <ul className="aboutMeList">
        {items.map((item, index) => (
          (index < 5 || !truncate) && (
            <Fragment key={`${heading}-${JSON.stringify(item)}`}>
              <li className="aboutMeListItem">
                <Dot number={1} fill color="#343741" inline margin="0 0.125rem 0.125rem 0" />
                <h4 className="aboutMeListItemText">
                  {listType === "awards" && <meta itemProp="award" content={item.title} />}
                  {listType === "languages" && <meta itemProp="knowsLanguage" content={item} />}
                  {(listType === 'licenses' && item[1].licenses)
                    ? <>
                      <span>{item[1].stateName}</span>
                      {item[1].licenses.length === 1
                        ? <span> - {item[1].licenses[0].number}</span>
                        : item[1].licenses.map(({ number }) => (
                          <div key={number}>
                            <Dot number={1} inline fill={false} margin="0 0.5rem 0.125rem 0" />
                            <span>{number}</span>
                          </div>)
                        )}
                    </>
                    : (listType === "awards" || listType === "education")
                      ? <>
                        {item.title && item.title}
                        {item.school && item.school}
                        {
                          item.degree && item.fieldOfStudy &&
                          <div>
                            <Dot number={1} inline fill={false} margin="0 0.5rem 0.125rem 0" />
                            {item.degree} in {item.fieldOfStudy}
                          </div>
                        }
                        {
                          !item.degree && item.fieldOfStudy &&
                          <div>
                            <Dot number={1} inline fill={false} margin="0 0.5rem 0.125rem 0" />
                            {item.fieldOfStudy}
                          </div>
                        }
                        {
                          item.degree && !item.fieldOfStudy &&
                          <div>
                            <Dot number={1} inline fill={false} margin="0 0.5rem 0.125rem 0" />
                            {item.degree}
                          </div>
                        }
                        {item.year &&
                          <div>
                            <Dot number={1} inline fill={false} margin="0 0.5rem 0.125rem 0" />
                            Graduated in {item.year}
                          </div>
                        }
                        {
                          item.years?.length > 0 &&
                          <div key={item.years}>
                            <Dot number={1} inline fill={false} margin="0 0.5rem 0.125rem 0" />
                            {item.years.join(", ")}
                          </div>
                        }
                      </>
                      : `${item.displayName || item}`
                  }
                </h4>
              </li>
            </Fragment>)
        ))}
      </ul>
      {items.length > 5 && <Link type="button" className={truncateLinkClass} onClick={() => setTruncate(!truncate)}>{`View ${truncate ? 'more' : 'less'}`}</Link>}
      <style jsx>{`
        @import './global-styles/tokens';
        .aboutMeListWrapper { 
          width: fit-content;
          break-inside: avoid-column;
          overflow: hidden; /* fix for Firefox */
          margin-right: 2rem;
          &:not(:first-child) {
            margin-top: 1rem;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              margin-top: 2rem;
            }
          }
        }
        .aboutMeList {
          width: fit-content;
          list-style: none;
          padding: 0;
          margin: 0.5rem 0 0 2.5rem;
        }
        .aboutMeListItem {
          display: flex;
        }
        .aboutMeListItemText{
          display: inline-block;
          font-weight: normal;
          padding: 0 0 0.25rem 0.5rem;
          margin: 0;
        }
        .aboutMeSubListItem {
          list-style: disc;
          line-height: 1.5rem;
        }
        .aboutMeAssociateContact {
          font-weight: 700;
          text-decoration: none;
        }
        .aboutMeIconHeading {
          display: flex;
        }
      `}</style>
      {headingStyles}
      {truncateLinkStyles}
    </div >
  );
};

const agentVideoUrlDomain = "comparioninsurance.onemob.com/v/";

const AgentInfo = ({
  agent,
  isAgentLanding = false
}) => {
  const isRemoteAgentWithLocalOffice = agent.isRemote && agent.localOffice && agent.localOffice.city && agent.localOffice.state?.name;
  const office = isRemoteAgentWithLocalOffice ? { address: agent.localOffice, location: agent.localOfficeLocation } : agent.offices[0];
  const agentProfile = agent.videoUrl && !agent.videoUrl.includes(agentVideoUrlDomain);
  const agentVideo = agent.videoUrl?.includes(agentVideoUrlDomain);
  let agentName = agent.preferredName || agent.firstName;

  if (/\s/g.test(agentName)) {
    agentName = agent.displayName;
  }

  const { className: officeInfoClass, styles: officeInfoStyles } = css.resolve`
    @import './global-styles/tokens';
    @media screen and (min-width: $lm-breakpoint-md-min) {
      * {
        width: 100%;
      }
    }  
  `;

  const { className: hoursWrapperClass, styles: hoursWrapperStyles } = css.resolve`
    @import './global-styles/tokens';
    *{
      margin-bottom: 1rem;

      @media screen and (min-width: $lm-breakpoint-md-min) {
        width: 100%;
        margin-right: 1rem;
        top: ${office.address.additionalStreetInfo ? '16.2rem' : '14.5rem'};
        margin-bottom: 0;
      }
    }
    *{
      @media screen and (min-width: $lm-breakpoint-xl-min) {
        margin-bottom: 1rem;
      }
    }
  `;

  return (
    <div className="agentInfo">
      <div className="quoteHoursLocationInfo">
        <AgentQuoteCard
          heading={`Quote with ${agentName}`}
          text="We're ready to offer personalized advice on your auto, home, life, or pet insurance needs."
          nNumber={agent.nNumber}
          buttonText="Start your quote"
          alias={isAgentLanding && agent.alias}
        />
        <div className="hoursLocationInfo">
          {agent.dailyHours &&
            <div className={`hours ${hoursWrapperClass}`}>
              <Hours dailyHours={agent.dailyHours ? agent.dailyHours : {}} />
            </div>}
          <div className="locationInfo">
            <OfficeInfoCard office={office} className={officeInfoClass} isAgentRemote={isRemoteAgentWithLocalOffice} reservationLink={agent.reservationUrl} />
          </div>
        </div>
      </div>

      <div className={`aboutMeSection${agentVideo ? ' hasVideo' : ''}`}>
        <Heading type="h3-bold" tag="h2">About me</Heading>
        {!!agentProfile &&
          <Link
            onBackground="whites"
            href={agent.videoUrl}
            aria-label="Watch my intro video"
            target="_blank"
            rel="noreferrer"
            variant="standalone"
          >
            Watch my intro video
          </Link>
        }
        {!!agentVideo &&
          <iframe className="agentVideo" title="Agent video" src={agent.videoUrl} allow="fullscreen" />
        }
        <div className="aboutMeSummary" dangerouslySetInnerHTML={{ __html: agent.bio }} />
        <div className="aboutMeLists">
          <AboutMeList heading="Licensed in" items={Object.entries(agent.licenseData)} listType="licenses" icon="ID" />
          <AboutMeList heading="Services" items={agentServicesOrdering.filter(item => agent.services.concat(agent.products).includes(item))} icon="SignDoc" />
          <AboutMeList heading="Languages" items={agent.languages.map(language => language.language)} listType="languages" icon="ProfessionalServices" />
          <AboutMeList heading="Education" items={agent.education.map(edu => edu)} listType="education" icon="Tuition" />
          <AboutMeList heading="Qualifications" items={agent.qualifications} listType="qualifications" icon="ProofofInsurance" />
          <AboutMeList heading="Awards & Honors" items={agent.awards.map(award => award)} listType="awards" icon="LifetimeRepairGuarantee" />
        </div>
      </div>
      <style global jsx>{`@import './AgentInfo.scoped.scss';`}</style>
      {hoursWrapperStyles}
      {officeInfoStyles}
    </div>
  );
};

AgentInfo.propTypes = {
  agent: PropTypes.shape({
    offices: PropTypes.arrayOf(PropTypes.shape({
      location: PropTypes.shape({
        coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
      }).isRequired,
      address: PropTypes.shape({
        street: PropTypes.string.isRequired,
        additionalStreetInfo: PropTypes.string,
        city: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
        state: PropTypes.shape({
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired
        }).isRequired
      }).isRequired,
    })),
    displayName: PropTypes.string,
    awards: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string
    })),
    licenses: PropTypes.shape({
      states: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
      }))
    }),
    languages: PropTypes.arrayOf(PropTypes.shape({
      language: PropTypes.string
    })),
    services: PropTypes.arrayOf(PropTypes.string),
    education: PropTypes.arrayOf(PropTypes.shape({ school: PropTypes.string, year: PropTypes.number, degree: PropTypes.string })),
  }).isRequired,
  isAgentLanding: PropTypes.bool,
};

export default AgentInfo;